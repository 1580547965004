import React, {
  createContext,
  useMemo,
} from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOf,
  shape,
  string,
  func
} from 'prop-types';

import { useDataModel } from '@thd-nucleus/data-sources';
import { useConfigService, useStore } from '@thd-nucleus/experience-context';
import { ABBREVIATED_FULFILLMENT_METHODS ,FEATURE_SWITCH_KEYS } from '../utils/constants';

import {
  buildRefinedNValue,
  hasCompatiblePromotion,
  transformResponseToContextValues
} from '../utils/promo-model-utils';
import {
  getItemGroupsForEachList,
  isTwoListUi
} from '../utils/promo-utils';
import { PromoModelProviderDataModel } from '../models/PromoModelProviderDataModel';
import { PromotionProductsLoader } from '../components/PromotionProductsLoader/PromotionProductsLoader';
import { isFulfillable, getFulfillmentService } from '../utils/promo-fulfillment-utils';
import { useNewRelicLogging } from '../hooks/useNewRelicLogging';
import { useRewardDrawer } from '../hooks/useRewardDrawer';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import {
  getExcludedStateSth
} from "@thd-olt-component-react/fulfillment/dist/pod/helpers/pod-fulfillment-utils";

export const defaultModelContext = {
  allListProductsAndAnchor: new Map(),
  analyticsComponent: '',
  analyticsSharedSection: '',
  anchorItem: null,
  applianceDeliveryStore: null,
  bogoMaxQuantity: 1,
  displayableFirstListItemIds: [],
  displayableSecondListItemIds: [],
  firstListPlaceholderItemIds: [],
  secondListPlaceholderItemIds: [],
  firstListLoading: false,
  secondListLoading: false,
  endDate: null,
  expDate: null,
  isAnchorFulfillable: false,
  isAnchorAppliance: false,
  isAwaitingData: false,
  isBackwardsBogo: false,
  isBmsm: false,
  isBogo: false,
  isDollarThresholdBogo: false,
  isExpiring: false,
  isForwardsB1gy: false,
  isForwardsBxg1: false,
  isForwardsBogo: false,
  isMsb: false,
  isMultiSelect: false,
  listHasFulfillment: false,
  longDesc: '',
  originalNvalue: null,
  refinedNvalue: null,
  rewardTiers: [{
    maxAllowedRewardAmount: 0,
    maxPurchaseQuantity: 0,
    minPurchaseAmount: 0,
    minPurchaseQuantity: 0,
    rewardAmountPerItem: 0,
    rewardAmountPerOrder: 0,
    rewardPercent: 0,
  }],
  shortDesc: '',
  src1EligibilityCriteria: {},
  src1MinPurchaseAmount: 0,
  startDate: null,
  experienceTag: '',
  subExperience: '',
  tgt1EligibilityCriteria: {},
  fromBogoCard: null,
};

export const PromoModelContext = createContext(defaultModelContext);

export const PromoModelProvider = ({
  anchorItem,
  hasUserInteracted,
  experienceTag,
  subExperienceTag,
  isMultiSelect,
  maAvailability,
  children,
  type,
  onFail,
}) => {
  const store = useStore();
  const rewardDrawerMaxLength = useConfigService(FEATURE_SWITCH_KEYS.rewardDrawerMaxLength) || 100;
  const rewardDrawerInitialLength = useConfigService(FEATURE_SWITCH_KEYS.rewardDrawerInitialLength) || 48;
  const showProgressOnLoad = !!useConfigService(FEATURE_SWITCH_KEYS.showProgressOnLoad);
  const isWaitingForMaAvailability = maAvailability?.isLoading;
  const skipPromotionProductsRequest = isWaitingForMaAvailability;
  const isWaitingForAnchorItemId = !anchorItem.itemId;
  const { sendBogoCardErrorsToNewRelic } = useNewRelicLogging();
  const { storeId, storeZip, membershipInformation } = store;

  // if provided, we use the brandRefinementId from the anchor product response for nvalue promos
  const { brandRefinementId } = anchorItem?.pricing?.conditionalPromotions?.[0] || {};
  const {
    data: promotionProductsData,
    loading: promotionProductsLoading
  } = useDataModel('promotionProducts', {
    variables: {
      itemId: anchorItem.itemId,
      pageSize: rewardDrawerInitialLength,
      // experienceTag, // this is for MA multi-promo
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    skip: skipPromotionProductsRequest,
    ssr: false,
  });

  const isWaitingForPromotionProducts = !skipPromotionProductsRequest && promotionProductsLoading;
  const promotion = promotionProductsData?.promotionProducts?.promotions?.[0];
  const originalNvalue = promotion?.nvalues?.[0];
  const refinedNvalue = buildRefinedNValue(originalNvalue, brandRefinementId);
  const [firstItemGroup, secondItemGroup] = getItemGroupsForEachList(promotion);
  const isWaitingForUser = !showProgressOnLoad && !hasUserInteracted;

  const {
    itemIdsForPlaceholders: firstListPlaceholderItemIds,
    products: firstListProducts,
    loading: firstListLoading,
    observer: firstListObserver,
    totalProducts: firstListTotalProducts,
    searchError: firstSearchModelCallError,
  } = useRewardDrawer({
    promotion,
    refinedNvalue,
    itemGroup: firstItemGroup,
    skip: isWaitingForUser,
    rewardDrawerMaxLength
  });

  const {
    itemIdsForPlaceholders: secondListPlaceholderItemIds,
    products: secondListProducts,
    loading: secondListLoading,
    observer: secondListObserver,
    totalProducts: secondListTotalProducts,
    searchError: secondSearchModelCallError,
  } = useRewardDrawer({
    promotion,
    refinedNvalue,
    itemGroup: secondItemGroup,
    skip: isWaitingForUser || !isTwoListUi(promotion),
    rewardDrawerMaxLength,
  });

  if (firstSearchModelCallError || secondSearchModelCallError) {
    let message;
    if (firstSearchModelCallError) {
      message = firstSearchModelCallError;
    } else if (secondSearchModelCallError) {
      message = secondSearchModelCallError;
    }
    const searchModelErrorMessage = `A search model call
    failed: ${message}`;
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-RewardFailedSearch',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: searchModelErrorMessage
    });
  }

  const isWaitingForSearch = firstListLoading || secondListLoading;
  const isAwaitingData = (
    isWaitingForAnchorItemId
    || isWaitingForMaAvailability
    || isWaitingForPromotionProducts
    || isWaitingForUser
    || isWaitingForSearch
  );

  const contextValue = useMemo(() => {
    if (!promotion) {
      return {
        ...defaultModelContext,
        anchorItem,
      };
    }

    const {
      startDate,
      endDate,
      expDate,
      isExpiring,
      shortDesc,
      longDesc,
      allListProductsAndAnchor,
      hasAnchorFromSearch,
      hasSearchData,
      isBogo,
      isMsb,
      isBmsm,
      isForwardsBogo,
      isBackwardsBogo,
      isForwardsB1gy,
      isForwardsBxg1,
      isDollarThresholdBogo,
      analyticsComponent,
      analyticsSharedSection,
      displayableFirstListItemIds,
      displayableSecondListItemIds,
      listHasFulfillment,
      isAnchorFulfillable,
      isAnchorAppliance,
      subExperience,
      rewardTiers,
      src1MinPurchaseAmount,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria,
    } = transformResponseToContextValues({
      promotion,
      firstListProducts,
      secondListProducts,
      anchorItem,
      refinedNvalue,
      maAvailability,
    });

    return {
      refinedNvalue,
      originalNvalue,
      anchorItem: allListProductsAndAnchor.get(anchorItem.itemId),
      anchorItemId: anchorItem.itemId,
      startDate,
      endDate,
      expDate,
      isExpiring,
      shortDesc,
      longDesc,
      isAwaitingData,
      allListProductsAndAnchor,
      hasAnchorFromSearch,
      hasSearchData,
      isBogo,
      isMsb,
      isBmsm,
      isForwardsBogo,
      isBackwardsBogo,
      isForwardsB1gy,
      isForwardsBxg1,
      isDollarThresholdBogo,
      isMultiSelect,
      analyticsComponent,
      analyticsSharedSection,
      displayableFirstListItemIds,
      displayableSecondListItemIds,
      firstListPlaceholderItemIds,
      secondListPlaceholderItemIds,
      firstListTotalProducts,
      secondListTotalProducts,
      listHasFulfillment,
      firstListObserver,
      secondListObserver,
      isAnchorFulfillable,
      isAnchorAppliance,
      experienceTag,
      subExperience,
      rewardTiers,
      bogoMaxQuantity: 1, // TODO link to dynamic data
      applianceDeliveryStore: maAvailability?.applianceDeliveryStore,
      src1MinPurchaseAmount,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria,
      fromBogoCard: type === 'card',
    };
  }, [
    isAwaitingData,
    isMultiSelect,
    maAvailability,
    promotion,
    firstListProducts,
    secondListProducts,
    anchorItem,
    refinedNvalue,
    originalNvalue,
    experienceTag,
    firstListPlaceholderItemIds,
    secondListPlaceholderItemIds,
    firstListTotalProducts,
    secondListTotalProducts,
    firstListObserver,
    secondListObserver,
    type,
  ]);

  // nvalue promos do not paginate yet due to MA parts and services atc limitations.
  const hasFirstListItems = Boolean(contextValue.displayableFirstListItemIds?.length);
  const hasSecondListItems = Boolean(contextValue.displayableSecondListItemIds?.length);
  const hasEmptyList = (!hasFirstListItems || (isTwoListUi(promotion) && !hasSecondListItems));

  const anchorIsUnfulfillable = contextValue.anchorItem?.itemId
    && contextValue.anchorItem?.fulfillment && !isFulfillable(contextValue.anchorItem);
  const incompatiblePromotion = (!isWaitingForPromotionProducts && !promotion)
    || (!isAwaitingData && !hasCompatiblePromotion(promotion, type));
  const isEmptyListOfItems = !isAwaitingData && (hasEmptyList || !contextValue.listHasFulfillment);

  const {getExcludedStateSth, getExcludedStateBoss } = podFulFillmentUtils;
  const fulfillmentOptionsAmount = contextValue.anchorItem?.fulfillment?.fulfillmentOptions?.length;
  const isExcludedShipState = fulfillmentOptionsAmount > 1
    ? getExcludedStateSth(contextValue.anchorItem) && getExcludedStateBoss(contextValue.anchorItem)
    : getExcludedStateSth(contextValue.anchorItem) || getExcludedStateBoss(contextValue.anchorItem);

  if (anchorIsUnfulfillable) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-Promotion Product',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'anchor item is unfulfillable'
    });
    if (typeof onFail === 'function') onFail(true);
    if (type === 'card') return null;
  }
  if (incompatiblePromotion) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-Incompatible',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'Incompatible promotion'
    });
    if (onFail) onFail(true);
    return null;
  }
  if (isEmptyListOfItems) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-AnchorFailedSearch',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: 'there are no items in the list'
    });
    if (onFail) onFail(true);
    if (type === 'card') return null;
  }
  if(isExcludedShipState) {
    sendBogoCardErrorsToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-Fallback-ExcludedShipState',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: null,
      anchorItemId: anchorItem.itemId,
      errorMessage: `shipping is excluded for this state: ${getExcludedStateBoss(contextValue.anchorItem) || getExcludedStateSth(contextValue.anchorItem)}`
    });
    return null;
  }

  // BOGO PHP TODO: we need to invoke onFail for MA unavailable scenarios?
  // what do we do with MA in BOGO card?

  return (
    <PromoModelContext.Provider value={contextValue}>
      {(!promotion || !anchorItem.itemId) ? (
        <PromotionProductsLoader
          experienceTag={experienceTag}
          subExperienceTag={subExperienceTag}
          type={type}
        />
      ) : (
        <>
          {children}
        </>
      )}
    </PromoModelContext.Provider>
  );
};

PromoModelProvider.displayName = 'PromoModelProvider';

PromoModelProvider.propTypes = {
  anchorItem: shape({
    itemId: string.isRequired,
    identifiers: shape({
      productType: string,
    }),
    pricing: shape({
      conditionalPromotions: arrayOf(shape({
        brandRefinementId: string,
      })),
    }),
  }).isRequired,
  hasUserInteracted: bool,
  experienceTag: string,
  subExperienceTag: string,
  isMultiSelect: bool.isRequired,
  maAvailability: shape({
    isAvailable: bool.isRequired,
    isLoading: bool.isRequired,
    applianceDeliveryStore: string,
  }),
  children: node.isRequired,
  type: oneOf(['card', 'pod']).isRequired,
  onFail: func
};

PromoModelProvider.defaultProps = {
  experienceTag: null,
  hasUserInteracted: false,
  subExperienceTag: null,
  maAvailability: null,
  onFail: null
};

PromoModelProvider.dataModel = PromoModelProviderDataModel;
